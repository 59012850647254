.top_navigation {
  background-color: #e8e8e8;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1;

  .top_navigation.fixed {
    position: fixed;
    top: 0;
    background-color: #e8e8e8;
    width: 100%;
    z-index: 99;
    -webkit-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  }
  .custom-nav-bar > .navbar {
    padding: 0;
    justify-content: space-between;
  }
  .custom-nav-bar > .navbar .navbar-brand {
    padding: 0px 0px;
  }
  .custom-nav-bar > .navbar .navbar-brand img {
    height: 100px;
    padding: 10px 0px;
  }
  .custom-nav-bar > .navbar > .navbar-collapse {
    flex-grow: 0;
  }
  .custom-nav-bar > .navbar > .navbar-collapse > .navbar-nav > .nav-item {
    position: static;
  }
  .custom-nav-bar > .navbar > .navbar-collapse > .navbar-nav > .nav-item > a {
    font-weight: 500;
    font-size: 18px;
    color: #60000f;
    padding: 15px 15px;
    position: relative;
    letter-spacing: 1px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item:last-child
    a {
    background-color: #ffffff;
    color: #60000f;
    font-weight: 400;
    border-radius: 50px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item:last-child
    a:hover {
    background-color: #60000f;
    color: #ffffff;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item
    > a
    > span {
    display: block;
    color: #969696;
    font-size: 14px;
    font-weight: 300;
    padding-top: 5px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item.active
    > a,
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item:hover
    > a {
    color: #60000f;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item.help
    > a {
    padding-right: 25px;
    padding-left: 35px;
    position: relative;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item.help
    > a:before {
    content: "\f059";
    font-family: "Font Awesome 5 Free";
    color: #999999;
    position: absolute;
    left: 14px;
    font-size: 20px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item.account
    > a {
    padding-right: 25px;
    padding-left: 35px;
    position: relative;
    color: #17479e;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item.account
    > a
    > span {
    color: #00aeef;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item.account
    > a:before {
    content: "\f2be";
    font-family: "Font Awesome 5 Free";
    color: #00adef;
    position: absolute;
    left: 10px;
    font-size: 20px;
  }
  .custom-nav-bar > .navbar > .navbar-collapse > .navbar-nav > .nav-item > ul {
    position: absolute;
    left: 0px;
    z-index: 11;
    width: 100%;
    background-color: #ffffff;
    list-style: none;
    padding: 0px;
    opacity: 0;
    display: none;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item
    > ul
    > li
    > a {
    color: #454545;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    font-weight: 500;
    font-size: 20px;
    text-transform: capitalize;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    padding: 15px 20px;
    display: block;
    border-bottom: solid 2px #454545;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item
    > ul
    > li
    > a:hover {
    background-color: #3f9aff;
    color: #fff;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li.has-submenu
    .arrow-down:after {
    padding: 0px 15px;
    cursor: pointer;
    z-index: 111;
    right: -5px;
    top: 20px;
    font-size: 20px;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li.has-submenu
    .arrow-down:after {
    font-family: "Font Awesome 5 Free";
    content: "\f067";
    position: absolute;
    color: #999999;
  }
  .custom-nav-bar > .navbar > .menu-btn {
    position: relative;
    z-index: 99;
    padding: 15px 5px;
    background: none;
    border: none;
    cursor: pointer;
  }
  .custom-nav-bar > .navbar > .menu-btn:hover,
  .custom-nav-bar > .navbar > .menu-btnfocus {
    outline: none;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > .nav-item
    > a
    .fa-search {
    color: #000;
  }
  .custom-nav-bar > .navbar > .navbar-collapse > .navbar-nav > li .sub_menu {
    position: absolute;
    z-index: 1;
    margin-top: 1px;
    padding-bottom: 20px;
    width: 100%;
    left: 0;
    background-color: #fff;
    display: none;
  }
  .custom-nav-bar > .navbar > .navbar-collapse > .navbar-nav > li .sub_menu ul {
    list-style: none;
    padding: 0px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    > ul {
    padding: 0px;
  }
  .custom-nav-bar > .navbar > .navbar-collapse > .navbar-nav > li .sub_menu li {
    float: left;
    width: 25%;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    li
    .info-menu
    li {
    width: 50%;
    float: left;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    .categories {
    font-size: 14px;
    font-weight: 600;
    padding-top: 25px;
    color: #000;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    .categories
    a {
    color: #f56b36;
    position: relative;
    display: block;
    padding-left: 40px;
    line-height: 35px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    .categories
    a
    img {
    height: 35px;
    width: auto;
    position: absolute;
    left: 0;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    > ul
    > li {
    float: none;
    width: 100%;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    > ul
    > li
    > a {
    color: #292b2f;
    line-height: 25px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    ul
    > li
    > ul
    > li
    > a:hover {
    color: #f56b36;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    li
    img {
    width: 100%;
    filter: grayscale(100%);
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    li
    > ul
    li {
    float: none;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    li
    > ul
    li
    a {
    color: #292b2f;
    line-height: 25px;
  }
  .custom-nav-bar
    > .navbar
    > .navbar-collapse
    > .navbar-nav
    > li
    .sub_menu
    li
    > ul
    li
    a:hover {
    color: #f56b36;
  }
}
