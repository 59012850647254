.style_screen{
    .product-filter-outer {
        background-color: #60000f;
        padding: 20px 0px;
        border-radius: 30px;
        margin-top: 30px;
    }
    .product-filter-list {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: center;
    }
    .product-filter-list .filter-items {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
        text-align: center;
    }
    .product-filter-list .filter-items::after {
        content: "";
        height: 40px;
        width: 3px;
        background-color: #ffffff;
        position: absolute;
        right: 0;
        top: 0px;
    }
    .product-filter-list .filter-items:last-child::after{
        height: 0px;
        width: 0px;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle {
      padding: 0;
      position: relative;
      border: none;
      height: 40px;
      width: 70px;
      border-radius: 2.5rem;
      color: #ffffff;
      background: #ffffff;
      margin-right: 10px;
    }
    .product-filter-list .filter-items .offers-only {
        color: #ffffff;
        font-size: 18px;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle:focus,
    .product-filter-list .filter-items .offers-only .btn-toggle.focus,
    .product-filter-list .filter-items .offers-only .btn-toggle:focus.active,
    .product-filter-list .filter-items .offers-only .btn-toggle.focus.active {
      outline: none;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle.active {
      transition: background-color 0.25s;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle.active > .handle {
      left: 1.6875rem;
      transition: left 0.25s;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle.active:after {
      opacity: 1;
    }
    
    .product-filter-list .filter-items .offers-only .btn-toggle.active {
      background-color: #ffffff;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle:focus,
    .product-filter-list .filter-items .offers-only .btn-toggle.focus,
    .product-filter-list .filter-items .offers-only .btn-toggle:focus.active,
    .product-filter-list .filter-items .offers-only .btn-toggle.focus.active {
      outline: none;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle > .handle {
      position: absolute;
      top: 0.3125rem;
      left: 0.3125rem;
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 1.875rem;
      background: #ffffff;
      transition: left 0.25s;
      border: 2px solid #60000f;
    }
    .product-filter-list .filter-items .offers-only .btn-toggle.active > .handle {
      left: 2rem;
      transition: left 0.25s;
    }
    .product-filter-list .filter-items .price-filter {
        font-size: 22px;
        color: #ffffff;
        cursor: pointer;
        line-height: 40px;
    }
    .product-filter-list .filter-items .short-filter {
        font-size: 22px;
        color: #ffffff;
        cursor: pointer;
        line-height: 40px;
    }
    .product-filter-list .filter-items .other-filter {
        font-size: 22px;
        color: #ffffff;
        cursor: pointer;
        line-height: 40px;
    }
    .product-filter-list .filter-items .other-filter span.filter-icon,
    .product-filter-list .filter-items .price-filter span.filter-icon,
    .product-filter-list .filter-items .short-filter span.filter-icon {
        color: #ffffff;
    }
    
    .sort-main{position: fixed;top: 0px;left: 0px;width: 100%;height: 100%;-webkit-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;-moz-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;-ms-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;-o-transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;transition: transform 0.3s ease 0s, opacity 0.3s ease 0s, visibility 0s ease 0.3s;opacity: 0;visibility: hidden;z-index: 999999;text-align: center;background-color: #5a5b5c;display: flex;align-items: center;min-height: 100vh;}
    .sort-main.open {opacity: 1;visibility: visible;-webkit-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;-moz-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;-ms-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;-o-transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;transition: opacity 0.3s ease 0s, transform 0.3s ease 0s;}
    .sort-overlay-close {
        margin-top: 50px;
    }
    .sort-overlay-close img {
        height: 70px;
        cursor: pointer;
    }
    .sort-list-inner {
        background-color: #ffffff;
        border: 0 none;
        border-radius: 10px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
        padding: 30px 20px;
        box-sizing: border-box;
        text-align: center;
        height: 100%;
        position: relative;
        cursor: pointer;
    }
    .sort-list-inner .sort-icon {
        margin-bottom: 20px;
    }
    .sort-list-inner .sort-icon img {
        height: 70px;
    }
    .sort-list-inner .sort-text {
        font-size: 22px;
        line-height: normal;
        color: #60000f;
    }
    .sort-main .col-md-3 {
        margin: 30px 0px;
    }



    .sub-category-outer {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .sub-category-list {
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,.2);
        box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
    }
    .sub-category-list .sub-cat-img a {
        display: block;
        overflow: hidden;
    }
    .sub-category-list .sub-cat-img a .image {
        width: 100%;
        -webkit-transition: all 0.6s ease-in-out;
        -moz-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
    }
    .sub-category-list .sub-cat-img a:hover .image {-webkit-transform: scale(1.2);-moz-transform: scale(1.2);transform: scale(1.2);}
    .sub-category-list .sub-cat-title {
        padding: 30px 20px;
    }
    .sub-category-list .sub-cat-title a {
        font-size: 24px;
        display: block;
        text-align: center;
        line-height: normal;
        color: #60000f;
    }
    .product-list .product-info {
        padding: 30px 20px;
    }
    .product-list .product-info .product-title {
        margin-bottom: 10px;
    }
    .product-list .product-info .product-title a {
        font-size: 24px;
        display: block;
        text-align: left;
        line-height: normal;
        color: #60000f;
    }
    .product-list .product-info .article-section {
        font-size: 18px;
        color: #666666;
        margin-bottom: 10px;
    }
    .product-list .product-info .size-color {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: center;
    }
    .product-list .product-info .size-color .size-part {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 10px;
    }
    .product-list .product-info .size-color .size-part ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
    }
    .product-list .product-info .size-color .size-part ul li {
        display: inline-block;
        font-size: 18px;
        color: #666666;
    }
    .product-list .product-info .size-color .size-part ul li span {
        font-size: 18px;
        color: #666666;
    }
    .product-list .product-info .size-color .color-part {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 10px;
    }
    .product-list .product-info .size-color .color-part ul {
        list-style: none;
        margin: 0px;
        padding: 0px;
        text-align: right;
        display: table;
        width: 100%;
    }
    .product-list .product-info .size-color .color-part ul li {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
    }
    .product-list .product-info .size-color .color-part ul li span {
        background-color: #000000;
        display: block;
        border-radius: 50%;
        text-align: center;
        border: 3px solid #666666;
        padding: 10px;
    }
    .product-list .product-info .size-color .color-part ul li span.col-60000f {
        background-color: #60000f;
    }
    .product-list .product-info .size-color .color-part ul li span.col-92ccff {
        background-color: #92ccff;
    }
    .product-list .product-info .size-color .color-part ul li span.col-fe8628 {
        background-color: #fe8628;
    }
    .product-list .product-info .discount-price {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px;
        align-items: center;
    }
    .product-list .product-info .discount-price .discount-precent {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .product-list .product-info .discount-price span.margin {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
    }
    .product-list .product-info .discount-price .mrp-price {
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
        text-align: right;
    }
    .product-list .product-info .discount-price .mrp-price span {
        font-size: 16px;
        color: #000000;
        font-weight: 500;
    }
    .product-list .product-info .discount-price .mrp-price span.price {
        color: #21c25d;
    }
    .product-list .product-info .size-color .color-part ul li:last-child a {
        background-color: transparent;
        border: none;
        border-radius: 0px;
        padding: 0px;
        color: #60000f;
        font-weight: 600;
        font-size: 15px;
    }
    .wish-cart-icon{display: none;}
    .sub-category-list:hover .wish-cart-icon{display: block;}
    .wish-cart-icon {
        position: absolute;
        top: 0;
        right: 0;
    }
    .wish-cart-icon ul {
        list-style: none;
        margin-bottom: 0px;
        padding: 0px;
        margin-top: 5px;
    }
    .wish-cart-icon ul li {
        display: inline-block;
        margin: 5px;
    }
    .wish-cart-icon ul li i {
        font-size: 20px;
        color: #ffffff;
        cursor: pointer;
    }
    .wish-cart-icon ul li:hover i {
        color: #ff0000;
    }
}