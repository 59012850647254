.subcategory_screen{
    .sub-category-outer {
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .sub-category-list {
        -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 1px 4px 0px rgba(0,0,0,.2);
        box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.2);
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .sub-category-list .sub-cat-img {}
    .sub-category-list .sub-cat-img a {
        display: block;
        overflow: hidden;
    }
    .sub-category-list .sub-cat-img a .image {
        width: 100%;
        -webkit-transition: all 0.6s ease-in-out;
        -moz-transition: all 0.6s ease-in-out;
        transition: all 0.6s ease-in-out;
    }
    .sub-category-list .sub-cat-img a:hover .image {-webkit-transform: scale(1.2);-moz-transform: scale(1.2);transform: scale(1.2);}
    .sub-category-list .sub-cat-title {
        padding: 30px 20px;
    }
    .sub-category-list .sub-cat-title a {
        font-size: 24px;
        display: block;
        text-align: center;
        line-height: normal;
        color: #60000f;
    }
}