.slide-item{
    margin-right:10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    .image-section{
        width: 200px;
        height: 200px;
        background-color: #e5e5e5;
        border-radius: 100%;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .label{
        color: #60000f;
        margin-top: 20px;
    }
}