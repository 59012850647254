.otp-style-bg {
    background-size: cover;
    background-position: center center;
    width: 100%;
    background-repeat: no-repeat;
    position: relative;
    background-image: url('../../assets/images/verification-bg.jpg');

    .middle-form-inner {
        background-color: #ffffff;
        border: 0 none;
        border-radius: 10px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
        padding: 30px 20px;
        box-sizing: border-box;
        max-width: 400px;
        margin: 50px auto;
        text-align: center;
    }
    .middle-form-outer {
        display: flex;
        align-items: center;
        min-height: 100vh;
    }
    .verification-main .top-logo img, .otp-main .top-info img {
        height: 100px;
    }
    .verification-main .top-info, .otp-main .top-info {
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 0px 25px;
    }
    .verification-main .top-info h4, .otp-main .top-info h4 {
        font-size: 18px;
        color: #60000f;
        text-align: center;
        margin-bottom: 10px;
        font-family: 'Asap', sans-serif;
    }
    .verification-main .top-info p, .otp-main .top-info p {
        font-size: 14px;
        color: #60000f;
        text-align: center;
        margin-bottom: 10px;
        font-family: 'Asap', sans-serif;
    }
    .middle-form-inner .verification-main form .form-group, .middle-form-inner .otp-main form .form-group {
        margin-bottom: 20px;
        position: relative;
        margin-left: 25px;
        margin-right: 25px;
    }
    .middle-form-inner .verification-main form .form-group .form-control {
        height: 50px;
        border-radius: 30px;
        padding: 10px 15px;
        padding-left: 40px;
        color: #60000f;
        border: 2px solid #60000f;
        outline: none;
        box-shadow: none;
        opacity: 0.6;
        font-size: 14px;
    }
    .middle-form-inner .otp-main form .form-group .form-control{
        height: 50px;
        border-radius: 30px;
        padding: 10px 15px;
        color: #60000f;
        border: 2px solid #60000f;
        outline: none;
        box-shadow: none;
        opacity: 0.6;
        font-size: 14px;
        text-align: center;
    }
    .middle-form-inner form .form-group .form-control:focus, .middle-form-inner .otp-main form .form-group .form-control:focus{opacity: 1;}
    .middle-form-inner form .form-group .form-control::-webkit-input-placeholder {color: #60000f;}
    .middle-form-inner form .form-group .form-control::-moz-placeholder {color: #60000f;}
    .middle-form-inner form .form-group .form-control:-ms-input-placeholder {color: #60000f;}
    .middle-form-inner form .form-group .form-control:-moz-placeholder {color: #60000f;}
    .middle-form-inner .verification-main form .form-group i {
        position: absolute;
        left: 2px;
        top: 0;
        padding: 18px 15px;
        color: #60000f;
        opacity: 0.6;
        font-size: 14px;
    }
    .middle-form-inner .verification-main form .form-group i.fas.fa-phone {
        transform: rotate(100deg);
        -ms-transform: rotate(100deg);
        -webkit-transform: rotate(100deg);
    }
    .middle-form-inner form .submit-btn {
        margin-top: 40px;
    }
    .middle-form-inner form .submit-btn .button-60000f {
        width: 100%;
        color: #ffffff;
        font-size: 14px;
        padding: 16px 25px;
        box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.4);
    }
    .otp-main .other-links {
        position: relative;
        margin-left: 25px;
        margin-right: 25px;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .otp-main .other-links a {
        position: relative;
        width: 100%;
        min-height: 1px;
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
    }
    .otp-main .other-links a:last-child {
        text-align: right;
    }
    .otp-main .other-links a {
        font-size: 14px;
        color: #60000f;
        text-align: left;
    }

  }