/*-- footer --*/
.footer-wrapper-main {
    background-color: #e9e9e9;
}
.footer-wrapper-main .footer-main {
    padding-top: 40px;
    padding-bottom: 0px;
}
.footer-wrapper-main .footer-main .footer-menu-title {
    font-size: 24px;
    color: #60000f;
    margin-bottom: 20px;
    font-family: 'Alata', sans-serif;
}
.footer-wrapper-main .footer-main .footer-menu-link {
    margin-bottom: 20px;
}
.footer-wrapper-main .footer-main .footer-menu-link ul {
    padding: 0px;
    list-style: none;
    margin: 0px;
}
.footer-wrapper-main .footer-main .footer-menu-link ul li {
    position: relative;
    margin-bottom: 5px;
}
.footer-wrapper-main .footer-main .footer-menu-link ul li a {
    display: inline-block;
    font-size: 15px;
    color: #60000f;
    letter-spacing: 0.8px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    font-family: 'Asap', sans-serif;
}
.footer-wrapper-main .footer-main .app-download-footer {
    margin-bottom: 20px;
}
.footer-wrapper-main .footer-main .app-download-footer h3 {
    font-size: 34px;
    color: #60000f;
    font-family: 'Bebas Neue', cursive;
    text-align: center;
    margin-bottom: 0px;
}
.footer-wrapper-main .footer-main .app-download-footer .store-btn {
    margin-top: 30px;
}
.footer-wrapper-main .footer-main .app-download-footer .store-btn .flex {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}
.footer-wrapper-main .footer-main .app-download-footer .store-btn .social-btns .app-btn {
    width: 50%;
    max-width: 200px;
    color: #fff;
    margin: 15px 5px;
    text-decoration: none;
    display: block;
}
.footer-wrapper-main .footer-main .app-download-footer .store-btn .social-btns .app-btn img {
    height: 100%;
    width: 100%;
}
.footer-wrapper-main .footer-main .footer-logo {
    text-align: center;
}
.footer-wrapper-main .footer-main .footer-logo a {
    display: inline-block;
}
.footer-wrapper-main .footer-main .footer-logo a img {
    height: 120px;
}
.footer-wrapper-main .footer-main .footer-social {
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #60000f;
}
.footer-wrapper-main .footer-main .footer-social ul {
    padding-top: 20px;
    padding-left: 0px;
    list-style: none;
}
.footer-wrapper-main .footer-main .footer-social ul li {
    display: inline-block;
    margin-right: 20px;
}
.footer-wrapper-main .footer-main .footer-social ul li a {
    display: block;
    font-size: 24px;
    text-align: center;
    color: #60000f;
}
.footer-wrapper-main .footer-main .footer-menu-link ul li a:hover, .footer-wrapper-main .footer-main .footer-menu-link ul li a:focus {
    color: #000000;
    text-decoration: underline;
    padding-left: 5px;
}
.footer-wrapper-main .copyright {
    padding-top: 20px;
    padding-bottom: 20px;
}
.footer-wrapper-main .copyright .copyright-text {
    text-align: center;
}
.footer-wrapper-main .copyright .copyright-text p {
    font-size: 15px;
    color: #60000f;
    margin-bottom: 0px;
    font-family: 'Asap', sans-serif;
}