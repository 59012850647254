.home_banner_wraper {
  position: relative;
}
.home_banner_wraper ul {
  padding: 0;
  list-style: none;
}

.home_banner_wraper .slick-prev {
  left: 6px;
  background-color: transparent;
}
.home_banner_wraper .slick-next {
  right: 6px;
  background-color: transparent;
}
.home_banner_wraper:hover .slick-prev {
  left: 6px;
  height: 35px;
  width: 30px;
}
.home_banner_wraper:hover .slick-next {
  right: 6px;
  height: 35px;
  width: 30px;
}
.home_banner_wraper .slick-dots {
  bottom: 15px;
  text-align: right;
}
.home_banner_wraper .home-slider .slide {
  position: relative;
}
.home_banner_wraper .slick-dots li,
.home_banner_wraper .slick-dots li button,
.home_banner_wraper .slick-dots li button::before {
  height: 20px;
  width: 20px;
}
.home_banner_wraper .slick-dots li button:before {
  background-color: transparent;
  border: 2px solid #ffffff;
  height: 20px;
  width: 20px;
}
.home_banner_wraper .slick-prev:before {
  content: "\f053";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: #49000c;
}
.home_banner_wraper .slick-next:before {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 30px;
  line-height: 35px;
  color: #49000c;
}
.home_banner_wraper .slick-dots li.slick-active button::before {
  background-color: #fde945;
  border-color: #fde945;
}
.home_banner_wraper .home-slider .slide:after {
  content: "";
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.home_banner_wraper .home-slider .slide .full-thumbnail img {
  width: 100%;
}

.home_banner_wraper .home-slider .slide .slide-caption {
  position: absolute;
  text-align: left;
  top: 50%;
  margin: 0 auto;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  padding-left: 0;
}
.home_banner_wraper .home-slider .slide .slide-caption img {
  opacity: 0;
}
.home_banner_wraper .home-slider .slide .slide-caption h1 {
  font-size: 50px;
  font-weight: 400;
  opacity: 0;
  color: #60000f;
  font-family: "Alata", sans-serif;
  margin-bottom: 30px;
}
.home_banner_wraper .home-slider .slide .slide-caption h4 {
  font-size: 18px;
  font-weight: 400;
  opacity: 0;
  color: #000000;
  font-family: "Alata", sans-serif;
  line-height: normal;
}
.home_banner_wraper .home-slider .slide .slide-caption p {
  opacity: 0;
  margin: 15px 0;
  font-size: 20px;
  line-height: 30px;
  max-width: 500px;
  margin-left: auto;
}
.home_banner_wraper .home-slider .slide .slide-caption p span {
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 40px;
}
.home_banner_wraper .home-slider .slide .slide-caption .banner-btn {
  margin-top: 40px;
  text-align: left;
}
.home_banner_wraper .home-slider .slide .slide-caption a {
  opacity: 0;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  font-size: 18px;
  letter-spacing: 0px;
  font-weight: 400;
  background-color: #e9e9e9;
  color: #60000f;
  font-family: "Alata", sans-serif;
  padding: 10px 30px;
  border: 1px solid #a0a0a0;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
}
.home_banner_wraper .home-slider .slide .slide-caption a:hover,
.home_banner_wraper .home-slider .slide .slide-caption a:focus {
  color: #ffffff;
  background-color: #60000f;
}
/* .home_banner_wraper .home-slider .slide .slide-caption a::before{border: 1px solid rgb(140, 200, 14);} */
.home_banner_wraper .home-slider .slide .slide-caption a::before,
.home_banner_wraper .home-slider .slide .slide-caption a::after {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
}
.home_banner_wraper .home-slider {
  margin-bottom: 0;
  padding: 0;
}

.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  img {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -ms-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  opacity: 1;
}
.home_banner_wraper .home-slider .slick-current.slick-active .slide-caption h1 {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: zoomInLeft;
  -moz-animation-name: zoomInLeft;
  -ms-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
  opacity: 1;
}
.home_banner_wraper .home-slider .slick-current.slick-active .slide-caption h4 {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: zoomInLeft;
  -moz-animation-name: zoomInLeft;
  -ms-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
  opacity: 1;
}
.home_banner_wraper .home-slider .slick-current.slick-active .slide-caption p {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: zoomInRight;
  -moz-animation-name: zoomInRight;
  -ms-animation-name: zoomInRight;
  animation-name: zoomInRight;
  opacity: 1;
}
.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  a.zoominleft {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: zoomInLeft;
  -moz-animation-name: zoomInLeft;
  -ms-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
  opacity: 1;
}
.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  a.lightspeedin {
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-name: lightSpeedIn;
  -moz-animation-name: lightSpeedIn;
  -ms-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  opacity: 1;
}

.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  img {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  -ms-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.home_banner_wraper .home-slider .slick-current.slick-active .slide-caption h1 {
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.home_banner_wraper .home-slider .slick-current.slick-active .slide-caption h4 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  animation-delay: 1s;
}
.home_banner_wraper .home-slider .slick-current.slick-active .slide-caption p {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -ms-animation-delay: 1s;
  animation-delay: 1s;
}
.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  a.zoominleft {
  -webkit-animation-delay: 1.6s;
  -moz-animation-delay: 1.6s;
  -ms-animation-delay: 1.6s;
  animation-delay: 1.6s;
}
.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  a.lightspeedin {
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -ms-animation-delay: 2s;
  animation-delay: 2s;
}

.home-slider .slide .slide-caption .slide-caption-wraper {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.home-slider .slide .slide-caption .slide-caption-wraper > .slide-caption-img {
  width: 30%;
}
.home_banner_wraper
  .home-slider
  .slick-current.slick-active
  .slide-caption
  img {
  margin: 0 auto;
}
.home-slider
  .slide
  .slide-caption
  .slide-caption-wraper
  > .slide-caption-content {
  width: 70%;
  text-align: left;
}

.quick-info-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(140, 138, 138, 0.8);
  -moz-box-shadow: 0px 6px 10px 0px rgba(140, 138, 138, 0.8);
  box-shadow: 0px 6px 10px 0px rgba(140, 138, 138, 0.8);
  background-color: #e9e9e9;
}
.quick-info-bar .quick-info-outer {
  text-align: center;
  margin: 10px 0px;
}
.quick-info-bar .quick-info-outer .quick-info-img {
  margin-bottom: 15px;
  text-align: center;
}
.quick-info-bar .quick-info-outer .quick-info-img img {
  height: 45px;
}
.quick-info-bar .quick-info-outer .quick-info-content h5 {
  font-size: 16px;
  color: #000000;
  line-height: 20px;
  font-family: "Alata", sans-serif;
  margin-bottom: 0px;
  font-weight: 400;
}

/*-- hm works --*/
.hm-works-main {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}
.title-underline {
  text-align: center;
  margin-bottom: 60px;
  position: relative;
}
.title-underline h2 {
  position: relative;
  font-size: 38px;
  color: #60000f;
  font-family: "Alata", sans-serif;
  font-weight: 400;
}
.title-underline h2::after {
  content: "";
  height: 4px;
  width: 35%;
  background-color: #60000f;
  position: absolute;
  bottom: -15px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.how-work-steps {
  margin-top: 30px;
  margin-bottom: 30px;
}
.work-main-img {
  text-align: right;
}
.how-work-steps ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.how-work-steps ul li {
  position: relative;
  margin-bottom: 20px;
}
.how-work-steps ul li a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.how-work-steps ul li a span.steps-count {
  margin-right: 20px;
  font-size: 18px;
  color: #60000f;
  font-family: "Alata", sans-serif;
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.how-work-steps ul li a .steps-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}
.how-work-steps ul li a .steps-info span.steps-icon {
  -ms-flex-item-align: center !important;
  align-self: center !important;
}
.how-work-steps ul li a .steps-info span.steps-icon img {
  height: 40px;
}
.how-work-steps ul li a .steps-info span.steps-content {
  -ms-flex: 1;
  flex: 1;
  margin-left: 15px;
}
.how-work-steps ul li a .steps-info span.steps-content h5 {
  font-size: 18px;
  color: #000000;
  font-family: "Alata", sans-serif;
  font-weight: 400;
}
.how-work-steps ul li a .steps-info span.steps-content p {
  font-size: 14px;
  color: #000000;
}
.how-work-steps ul li a .step-outer {
  padding: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  -ms-flex: 1;
  flex: 1;
}
.how-work-steps ul li a:hover .step-outer {
  background-color: #eaeaea;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.hm-download-app {
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(140, 138, 138, 0.8);
  -moz-box-shadow: 0px 2px 8px 0px rgba(140, 138, 138, 0.8);
  box-shadow: 0px 2px 8px 0px rgba(140, 138, 138, 0.8);
  background-color: #e9e9e9;
}
.how-work-content {
  margin-bottom: 50px;
}
.download-title h3 {
  font-size: 38px;
  color: #60000f;
  font-family: "Alata", sans-serif;
  font-weight: 400;
  margin: 0px;
  line-height: normal;
}
.download-title {
  padding: 20px 0px;
}
.hm-download-app .store-btn .flex {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: flex-end;
}
.hm-download-app .store-btn .app-btn {
  width: 50%;
  max-width: 200px;
  color: #fff;
  margin: 15px 5px;
  text-decoration: none;
  display: block;
}
.hm-download-app .store-btn .app-btn img {
  width: 100%;
  height: 100%;
}

/*-- fulfilled products --*/
.fulfilled-products-main {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-box-shadow: 0px 6px 10px 0px rgba(140, 138, 138, 0.8);
  -moz-box-shadow: 0px 6px 10px 0px rgba(140, 138, 138, 0.8);
  box-shadow: 0px 6px 10px 0px rgba(140, 138, 138, 0.8);
  background-image: url("../../assets/images/fulfilled-bg.jpg");
}
.normal-title {
  margin-bottom: 60px;
}
.normal-title h2 {
  font-size: 48px;
  color: #60000f;
  font-family: "Bebas Neue", cursive;
  text-align: center;
  margin-bottom: 0px;
  letter-spacing: 1px;
}
.fulfilled-outer {
  padding: 20px;
  text-align: center;
  background-color: transparent;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: 2px solid #60000f;
  margin: 20px 0px;
}
.fulfilled-outer .fulfilled-icon {
  margin-bottom: 15px;
}
.fulfilled-outer .fulfilled-icon img {
  height: 90px;
}
.fulfilled-outer .fulfilled-title h4 {
  font-size: 24px;
  color: #60000f;
  font-weight: 400;
}
.upload-kyc-button {
  text-align: center;
  margin-top: 50px;
}
.upload-kyc-button .button-60000f {
  color: #ffffff;
  font-size: 18px;
  padding: 16px 25px;
}

.customers-says-main {
  padding-top: 60px;
  padding-bottom: 60px;
}
.testimonial-slider {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.testimonial-slider .client-testimonial .client-image img {
  height: 130px;
  margin: 0 auto;
}
.testimonial-slider .client-testimonial .client-name {
  margin-top: 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}
.testimonial-slider .client-testimonial .client-company {
  font-size: 12px;
  font-weight: 400;
}
.testimonial-slider .client-testimonial .testimonial-content {
  margin-top: 20px;
}
.testimonial-slider .client-testimonial .testimonial-content h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.testimonial-slider .client-testimonial .testimonial-content p {
  font-size: 16px;
  line-height: normal;
  font-style: italic;
}

/*-- gt app link --*/
.bottom-line-left {
  bottom: 0;
  left: 0;
}
.bottom-line-left img {
  height: 200px;
}
.get-app-main {
  padding-top: 40px;
  padding-bottom: 100px;
  border-bottom: 5px solid #730111;
  position: relative;
}
.get-app-img {
  text-align: right;
}
.get-mobile-link {
  margin-top: 30px;
  margin-bottom: 30px;
  padding-left: 60px;
}
.get-mobile-link h2 {
  font-size: 60px;
  color: #730111;
  line-height: normal;
  text-transform: uppercase;
  font-weight: 400;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 3px;
  margin-bottom: 15px;
}
.get-mobile-link h2 img {
  height: 75px;
}
.get-mobile-link p {
  font-size: 20px;
  line-height: normal;
  color: #666666;
}
.get-mobile-link .send-link-wrap {
  margin-top: 30px;
  margin-bottom: 15px;
  position: relative;
}
.get-mobile-link .send-link-wrap input.form-control {
  height: 50px;
  border-radius: 30px;
  padding: 10px 15px;
  color: #60000f;
  border: 2px solid #60000f;
  outline: none;
  font-size: 16px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.4);
}
.get-mobile-link .send-link-wrap .send-link-btn {
  margin-bottom: 1rem;
}
.get-mobile-link .send-link-wrap .send-link-btn .btn-submit {
  background-color: #60000f;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  padding: 12px 25px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border-radius: 30px;
}
.get-mobile-link .app-store-link {
  margin-top: 10px;
}
.get-mobile-link .app-store-link .store-img-link {
  margin: 15px 0px;
}
.get-mobile-link .app-store-link .store-img-link a {
  display: block;
  width: 100%;
  overflow: hidden;
}
.get-mobile-link .app-store-link .store-img-link a img {
  width: 100%;
}

/*-- join newsletter --*/
.newsletter-main {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
}
.both-corner-icon .corner-icon-left {
  position: absolute;
  top: 0;
  left: 0;
}
.both-corner-icon .corner-icon-right {
  position: absolute;
  top: 0;
  right: 0;
}

.newsletter-main .newsletter-content {
  text-align: center;
}
.newsletter-main .newsletter-content .newsleter-top {
  margin-bottom: 30px;
}
.newsletter-main .newsletter-content .newsleter-top h4 {
  font-size: 30px;
  color: #5c370b;
  margin-bottom: 20px;
  font-family: "Asap", sans-serif;
}
.newsletter-main .newsletter-content .newsleter-top p {
  font-size: 24px;
  color: #5c370b;
  margin-bottom: 20px;
  font-family: "Asap", sans-serif;
  line-height: normal;
}
.newsletter-main .newsletter-content .newsletter-bottom .newsletter-block {
  max-width: 350px;
  margin: auto;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
}
.newsletter-main .newsletter-content .newsletter-bottom .newsletter-block form {
  width: 100%;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  input[type="email"] {
  padding-left: 40px;
  border-radius: 0;
  margin: auto;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #000000;
  height: 44px;
  width: 100%;
  font-size: 18px;
  color: #60000f;
  outline: none;
  opacity: 0.7;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  input:focus[type="email"] {
  opacity: 1;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  button {
  padding: 15px 30px;
  font-size: 18px;
  color: #ffffff;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  .newsletter-input {
  position: relative;
  margin-bottom: 30px;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  .newsletter-input
  input::-webkit-input-placeholder {
  font-size: 18px;
  font-family: "Della Respira", serif;
  font-weight: 400;
  color: #60000f;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  .newsletter-input
  input::-moz-placeholder {
  font-size: 18px;
  font-family: "Della Respira", serif;
  font-weight: 400;
  color: #60000f;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  .newsletter-input
  input:-ms-input-placeholder {
  font-size: 18px;
  font-family: "Della Respira", serif;
  font-weight: 400;
  color: #60000f;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  .newsletter-input
  input:-moz-placeholder {
  font-size: 18px;
  font-family: "Della Respira", serif;
  font-weight: 400;
  color: #60000f;
}
.newsletter-main
  .newsletter-content
  .newsletter-bottom
  .newsletter-block
  form
  .newsletter-input::before {
  content: "\f0e0";
  font-family: "Font Awesome 5 Free";
  color: #60000f;
  position: absolute;
  font-size: 20px;
  top: 14px;
  left: 6px;
}

.category-section {
  padding-top: 20px;
  padding-bottom: 20px;
}
.singla-heading-witharrow {
  text-align: center;
}
.singla-heading-witharrow h4 {
  position: relative;
  font-size: 38px;
  display: inline-block;
  color: #60000f;
  font-family: "Alata", sans-serif;
  margin-bottom:20px;
}
